import { METRIC_CONSTANTS } from '../../../utils/constantsByLevel';
import { buildBaseDrillLink, buildBaseShrinkUrl } from './url';
import { capitalizeFirstLetter } from '../../../utils/string';
import { getPercentString } from '../../Common/TableCellUtils';

export const cullMarkdownsHeaders = level => [
  { name: `${capitalizeFirstLetter(METRIC_CONSTANTS[level].urlLinkLevel)} # ` },
  { name: 'Value' },
  { name: 'Goal' },
  { name: '% of Stores Passing' }
];

export const shrinkCullMdBreadcrumbItems = (level, locNbr) => {
  const baseLevelShrinkUrl = buildBaseShrinkUrl(level);
  return [
    { to: `/${baseLevelShrinkUrl}?${METRIC_CONSTANTS[level].urlParam}=${locNbr}`, text: 'Back to Shrink Overview' },
    { text: 'Cull Markdowns' }
  ];
};

export const shrinkCullMdRows = (metricsScoreData, metricsData, timeframe, level) => {
  const baseLevelShrinkUrl = buildBaseDrillLink(level);
  const previousLocNbr = metricsScoreData[METRIC_CONSTANTS[level].propToRender];
  return [
    {
      stringValue: previousLocNbr,
      link: {
        to: `/${baseLevelShrinkUrl}/cullmd`,
        params: {
          [METRIC_CONSTANTS[level].urlSubLevelParam]: previousLocNbr
        }
      }
    },
    {
      stringValue: getPercentString(
        metricsData[previousLocNbr][timeframe + '_CULL_MD_NUM'] / metricsData[previousLocNbr][timeframe + '_CULL_MD_DEN']
      )
    },
    { stringValue: `≥ ${getPercentString(metricsScoreData['CULL_MD_GOAL'], 2)}` },
    { stringValue: getPercentString(metricsScoreData['CULL_MD_SCORE']), orderBy: metricsScoreData['CULL_MD_SCORE'] }
  ];
};
